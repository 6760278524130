































































































































































































































































import '@/components/Vue2Leaflet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import date from 'quasar/src/utils/date.js';;

@Component({})
export default class MobilityConfigureAnalyzer extends Vue {
  @Prop() public id!: number;

  public $route: any;
  public $router: any;
  public $q: any;
  public $store: any;

  public loading: boolean = false;
  private analyzer: any = null;
  private mapUrl: string = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
  private members: any = [];
  private orgmembers: any = [];
  private member: any = {
    user_email: '',
    user_firstname: '',
    user_lastname: '',
    user_id: null,
    dt_from: null,
    dt_to: null,
  };
  private dialogMember: boolean = false;
  private dialogMemberAction: string = 'new';

  public async created() {
    this.refresh();
  }

  private refresh() {
    const analyzerId: number = this.id;
    return Promise.all([
      this.$store.dispatch('loadAnalyzer', {
        analyzerId,
      }).then((response: any) => {
        this.analyzer = response;
      }),

      this.$store.dispatch('loadAnalyzerMembers', {
        analyzerId,
      }).then((response: any) => {
        this.members = response.members;
      }),

      this.$store.dispatch('loadOrganisationMembers')
      .then((response: any) => {
        this.orgmembers = response.members;
      }),
    ]).then(() => {
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private getUser(email: any) {
    const entry = this.availableOrganisationMembers.find(
      (user: any) => user.email === email);
    if (entry !== undefined) {
      return entry;
    }
    return null;
  }

  private getUserById(userId: any) {
    const entry = this.availableOrganisationMembers.find(
      (user: any) => user.id === userId);
    if (entry !== undefined) {
      return entry;
    }
    return null;
  }

  get userExists() {
    return this.member.user_id || this.getUser(this.member.user_email) !== null;
  }

  get userAddMessage() {
    if (this.member.user_email.length === 0) {
      return '';
    }
    if (!this.userExists) {
      return (
        `L'utilisateur ${this.member.user_email} n'existe pas sur CozyConnect.\n` +
        `Un mail sera envoyé afin de l'inviter sur la plateforme.`
      );
    }
  }

  get canAddMember() {
    if (this.member.user_id !== null) {
      return true;
    }
    if (this.member.user_email.length < 6) {
      return false;
    }
    if (this.member.user_firstname.length < 2) {
      return false;
    }
    if (this.member.user_lastname.length < 2) {
      return false;
    }
    return true;
  }

  @Watch('member', {deep: true})
  private updateMemberInfo() {
    if (this.member.user_id) {
      const user = this.getUserById(this.member.user_id);
      this.member.user_firstname = user.firstname;
      this.member.user_lastname = user.lastname;
    }
    if (this.member.user_email && this.member.user_email.length > 0) {
      const user = this.getUser(this.member.user_email);
      if (user === null) {
        this.member.user_id = null;
      } else {
        this.member.user_id = user.id;
      }
    }
  }

  private showAddMember() {
    this.$set(this, 'member', {
      user_email: '',
      user_firstname: '',
      user_lastname: '',
      user_id: null,
      dt_from: null,
      dt_to: null,
    });
    this.dialogMemberAction = 'new';
    this.dialogMember = true;
  }

  private showUpdateMember(member: any) {
    const newMember = {
      user_id: member.user_id,
      user_firstname: null,
      user_lastname: null,
      dt_from: this.isoToFormat(member.dt_from),
      dt_to: this.isoToFormat(member.dt_to),
    };
    this.$set(this, 'member', newMember);
    this.dialogMemberAction = 'update';
    this.dialogMember = true;
  }

  private addMember() {
    const analyzerId = this.id;
    const payload = {
        dt_from: this.formatToIso(this.member.dt_from),
        dt_to: this.formatToIso(this.member.dt_to),
    };

    if (this.member.user_id !== null) {
      payload['user_id'] = this.member.user_id;
    } else {
      payload['user_email']  = this.member.user_email;
      payload['user_firstname']  = this.member.user_firstname;
      payload['user_lastname']  = this.member.user_lastname;
    }

    this.$store.dispatch('addAnalyzerMember', {
      analyzerId, payload,
    }).then((response: any) => {
      this.refresh();
      this.dialogMember = false;
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private updateMember() {
    this.$store.dispatch('updateAnalyzerMember', {
      analyzerId: this.id,
      userId: this.member.user_id,
      payload: {
        dt_from: this.formatToIso(this.member.dt_from),
        dt_to: this.formatToIso(this.member.dt_to),
      },
    }).then((response: any) => {
      this.refresh();
      this.dialogMember = false;
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private removeMember(member: any) {
    this.$q.dialog({
      title: this.$t('user_delete_title'),
      message: this.$t('user_delete_message'),
    }).onOk(() => {
      this.$store.dispatch('removeAnalyzerMember', {
        analyzerId: this.id,
        userId: member.user_id,
      }).then((response: any) => {
        this.refresh();
        this.dialogMember = false;
      }).catch((error: any) => {
        this.globalError(error);
      });
    });
  }

  private formatToIso(dt: null | string) {
    if (dt === null) {
      return null;
    }
    const d = date.extractDate(dt, 'YYYY-MM-DD HH:mm');
    return date.formatDate(d, 'YYYY-MM-DDTHH:mm:ss') + 'Z';
  }

  private isoToFormat(dt: null | string) {
    if (dt === null) {
      return null;
    }
    dt = dt.slice(0, -1);
    const d = date.extractDate(dt, 'YYYY-MM-DDTHH:mm:ss');
    return date.formatDate(d, 'YYYY-MM-DD HH:mm');
  }

  get availableOrganisationMembers() {
    return this.orgmembers.filter((member: any) => {
      return member;
    });
  }
}
