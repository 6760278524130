import Vue from 'vue';
import { LMap, LPolyline, LTileLayer, LMarker, LIcon, LPopup, LImageOverlay, LGeoJson, LControl, LControlZoom } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LPolyline', LPolyline);
Vue.component('LMarker', LMarker);
Vue.component('LIcon', LIcon);
Vue.component('LPopup', LPopup);
Vue.component('LImageOverlay', LImageOverlay);
Vue.component('LGeoJson', LGeoJson);
Vue.component('LControl', LControl);
Vue.component('LControlZoom', LControlZoom);

const iconDefault: any = Icon.Default;
delete iconDefault.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
